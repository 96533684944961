html {
  height: 100%;
  overflow: hidden;
}

html,
body {
  background-color: white;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#inner-item-details::-webkit-scrollbar {
  display: none;
  width: 0px;
}

div::-webkit-scrollbar,
p::-webkit-scrollbar,
table::-webkit-scrollbar {
  display: none;
  width: 0;
}

/* BEING GOOGLE AUTO COMPLETE STYLES */
.pac-container {
  z-index: 10000 !important;
  box-shadow: none;
  padding: 0 8px;
  border-radius: 8px;
}

.pac-item {
  padding: 8px 0;
  cursor: pointer;
}

.pac-item:last-child,
.pac-item:first-child {
  border-bottom: none;
}

.pac-item span {
  font-size: 14px;
  font-weight: 400;
}

.pac-item .pac-item-query .pac-matched:first-child {
  font-weight: 600;
}

.pac-icon {
  background-image: url(https://d270la2grz697.cloudfront.net/_next/static/media/location.7b0393e6.svg) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 18px;
  background-position: center;
}

.grecaptcha-badge {
  visibility: hidden;
}
/* END GOOGLE AUTO COMPLETE STYLES */

/* latin-ext */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/204a0a5e1cf12efb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/2cc74b96cdc9383d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/ae6a2fe692ea80aa-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/c99ef75952aca458-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/bd907a34e79d0cf5-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/7c1535108079abc4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/8727a9eb9a9ed107-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/cf1f69a0c8aed54d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/fe0777f1195381cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/eafabf029ad39a43-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/f10b8e9d91f3edcb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/8888a3826f4a3af4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/c3bc380753a8436c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/a1386beebedccca4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_e662b2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://d270la2grz697.cloudfront.net/_next/static/media/b957ea75a84b6ea7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_e662b2';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_e662b2 {font-family: '__Poppins_e662b2', '__Poppins_Fallback_e662b2', sans-serif
}.__variable_e662b2 {--font-poppins: '__Poppins_e662b2', '__Poppins_Fallback_e662b2', sans-serif
}

