html {
  height: 100%;
  overflow: hidden;
}

html,
body {
  background-color: white;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#inner-item-details::-webkit-scrollbar {
  display: none;
  width: 0px;
}

div::-webkit-scrollbar,
p::-webkit-scrollbar,
table::-webkit-scrollbar {
  display: none;
  width: 0;
}

/* BEING GOOGLE AUTO COMPLETE STYLES */
.pac-container {
  z-index: 10000 !important;
  box-shadow: none;
  padding: 0 8px;
  border-radius: 8px;
}

.pac-item {
  padding: 8px 0;
  cursor: pointer;
}

.pac-item:last-child,
.pac-item:first-child {
  border-bottom: none;
}

.pac-item span {
  font-size: 14px;
  font-weight: 400;
}

.pac-item .pac-item-query .pac-matched:first-child {
  font-weight: 600;
}

.pac-icon {
  background-image: url(../assets/icons/location.svg) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 18px;
  background-position: center;
}

.grecaptcha-badge {
  visibility: hidden;
}
/* END GOOGLE AUTO COMPLETE STYLES */
